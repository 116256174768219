<script setup>
import { useShiftStore } from "@/stores/shift";
import { mapErrors } from "@/utilities";
import { useForm } from "@inertiajs/vue3";
import { computed, inject } from "vue";

// Components
import Button from "@/Components/Button/Button.vue";

// Partials
import Calendar from "../Shifts/Partials/Calendar.vue";
import Form from "./Partials/Form.vue";

// Layouts
import LocationLayout from "@/Layouts/LocationLayout.vue";

// Icons
import saveIcon from "@i/save-white.svg";

// Variables
const route = inject("route");
const dayjs = inject("dayjs");

const props = defineProps({
  query: Object,
  directApprovalConditionsOptions: Object,
  positionOptions: Object,
  dresscodeOptions: Object,
  shiftCountsByDate: Object
});

const store = useShiftStore();

store.load({
  date: props.query.start_date
});

const form = useForm({
  multiData: [
    {
      start_date: "",
      end_date: "",
      start_time: "10:00",
      end_time: "15:00",
      break: "0"
    }
  ],
  position_id: "",
  description: "",
  number_of_shifts: 1,
  travel_reimbursement: false,
  visible_to_freelancers: false,
  freelancer_hourly_wage: null,
  additional_wage: 0,
  additional_start_dates: [],
  direct_approval_conditions: [],
  dresscode: "",
  ...props.query
});

const sendForm = () => {
  form
    .transform(data => ({
      ...data,
      multiData: data.multiData.map(item => ({
        ...item,
        start: dayjs(item.start_date + " " + item.start_time).unix(),
        end: dayjs(item.start_date + " " + item.end_time).unix(),
        startDb: dayjs(item.start_date + " " + item.start_time).unix(),
        endDb: dayjs(item.end_date + " " + item.end_time).unix()
      })),

      freelancer_hourly_wage: data.visible_to_freelancers
        ? data.freelancer_hourly_wage
        : 1400,
      userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }))
    .post(route("location.shifts.store"), {
      preserveState: true,
      preserveScroll: true,
      onError: errors => (form.errors = mapErrors(errors))
    });
};
const date = computed(() =>
  form.start_date ? form.start_date : dayjs().format("YYYY-MM-DD")
);
const dateAfterDay = computed(() =>
  form.start_date
    ? dayjs(form.start_date).add(1, "day")
    : dayjs()
        .add(1, "day")
        .format("YYYY-MM-DD")
);
const start = computed(() => dayjs(date.value + " " + form.start_time));
const end = computed(() => {
  let end = dayjs(date.value + " " + form.end_time);
  if (end.isBefore(start.value)) {
    end = dayjs(dateAfterDay.value.format("YYYY-MM-DD") + " " + form.end_time);
  }
  return end;
});
</script>

<template>
  <LocationLayout>
    <div class="text-blue">
      <h1 class="font-bold text-[32px] mb-2">{{ $t('Add shift') }}</h1>

      <FormKit type="form" id="ShiftForm" @submit="sendForm" :actions="false">
        <Form
          :form="form"
          :positionOptions="positionOptions"
          :dresscodeOptions="dresscodeOptions"
          :directApprovalConditionsOptions="directApprovalConditionsOptions"
        >
          <!-- <h4 class="mb-2 font-bold text-4">{{ $t('Copy') }}</h4>
          <Calendar :form="form" v-model:additionalStartDates="form.additional_start_dates" />-->
        </Form>
      </FormKit>
      <div class="flex justify-end">
        <Button
          orange
          type="button"
          :processing="form.processing"
          :recentlySuccessful="form.recentlySuccessful"
          :text="$t('Save')"
          :icon="saveIcon"
          @click="sendForm"
        />
      </div>
    </div>
  </LocationLayout>
</template>
